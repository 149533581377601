<template>
  <div class="sales-total fill-height">
    <v-card class="card-widget" height="100%">
      <v-layout fill-height>
        <v-card-text>
          <v-row no-gutters>
            <v-col sm="4">
              <v-row class="flex-grow-0" no-gutters>
                <v-col cols="1" class="mr-2">
                  <v-icon color="primary">mdi-store</v-icon>
                </v-col>
                <v-col cols="10" class="mb-3 subtitle-2"> {{ title }}</v-col>
                <v-col cols="12">
                  <v-divider class="mb-3 mt-2"></v-divider>
                </v-col>
                <v-col class="d-flex align-start justify-space-between">
                  <v-layout column>
                    <v-layout>
                      <v-icon v-if="b.amount === undefined || (b.amount === 0 && amount === 0)" color="green">mdi-chevron-up-circle-outline</v-icon>
                      <v-icon v-else :color="getPercent(amount,b.amount) === 0.00 ? 'orange darken-1' : getPercent(amount,b.amount) > 0.00 ? 'green' : 'red'">
                        {{getPercent(amount,b.amount) === 0.00 ? 'mdi-minus-circle-outline' : getPercent(amount,b.amount) > 0.00 ? 'mdi-chevron-up-circle-outline' : 'mdi-chevron-down-circle-outline'}}
                      </v-icon>
                      <small v-if="b.amount === undefined || b.amount === 0" class="subtitle-1 green--text">---%</small>
                      <small v-else class="subtitle-1" :class="getPercent(amount,b.amount) === 0.00 ? 'orange--text text--darken-1' : getPercent(amount,b.amount) > 0.00 ? 'green--text' : 'red--text'">
                        {{getPercent(amount,b.amount) !== 0.00 ? $options.filters.percent(getPercent(amount,b.amount),2,100, { decimalSeparator: ',' }) : '---%' }}
                      </small>
                    </v-layout>
                  </v-layout>
                  <v-layout column align-center justify-center>
                    <div v-if="b.amount === undefined || b.amount === 0">0,00</div>
                    <div v-else>{{ currency(b.amount) }}</div>
                    <small class="caption grey--text">
                      anterior
                    </small>
                  </v-layout>
                  <v-layout column align-center>
                    <div>{{ currency(amount) }}</div>
                    <small class="caption grey--text">
                      atual
                    </small>
                  </v-layout>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: ['title', 'amount', 'b'],
  computed: {
    getPercent() {
      return (amountA, amountB) => ((amountA - amountB) / amountA)
    }
  }
}
</script>

<style lang="scss">

</style>
