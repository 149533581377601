<template>
  <page class="comparative-brief" title="Resumo Comparativo por Filial">
    <v-snackbar v-model="showSnackbar" top color="error">
      {{messageSnackbar}}
    </v-snackbar>
    <v-card tile color="grey lighten-3 mb-3" elevation="0">
      <v-card-text>
        <v-row class="flex-grow-0 align-end">
          <v-col sm="12">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="sales.currentStartDate" type="date" label="Data Inicial Atual" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="sales.currentEndDate" type="date" label="Data Final Atual" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0 pb-3 d-md-none">
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="sales.previousStartDate" type="date" label="Data Inicial Anterior" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="sales.previousEndDate" type="date" label="Data Final Anterior" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex justify-center justify-md-start">
            <v-btn :block="$vuetify.breakpoint.smOnly" color="primary" @click="onClickSearch">
              <v-progress-circular v-if="isLoading" size="16" width="2" indeterminate class="mr-1" />
              <span>{{ isLoading ? 'Buscando' : 'Buscar'}}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="flex-grow-0">
      <v-col cols="12">
        <sales-comparative-total-general title="Total Geral" :amount="sales.salesComparativeTotal.A.amount" :b="sales.salesComparativeTotal.B || {}" />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col v-for="(item, i) in sales.salesFilialsComparativeTotal.A" cols="12" sm="4" :key="i">
        <sales-comparative-total :title="item.desc" :amount="item.amount" :b="sales.salesFilialsComparativeTotal.B[i] || {}" />
      </v-col>
    </v-row>
  </page>
</template>

<script>
import Page from "@/components/containers/page";
import mixins from "@/mixins"
import SalesComparativeTotal from "@/components/widgets/sales-comparative-total";
import SalesComparativeTotalGeneral from "@/components/widgets/sales-comparative-total-general";
export default {
  mixins: [mixins],
  components: {SalesComparativeTotalGeneral, SalesComparativeTotal, Page},
  data: () => ({
    showSnackbar: false,
    messageSnackbar: '',
    clicked: false,
    isLoading: false
  }),
  mounted() {
    this.getSales();
  },
  computed: {
    sales() {
      return this.$store.state.millennium;
    }
  },
  methods: {
    getSales() {
      this.$store.dispatch('millennium/getComparativeSales', {
        currentStartDate: this.sales.currentStartDate,
        currentEndDate: this.sales.currentEndDate,
        previousStartDate: this.sales.previousStartDate,
        previousEndDate: this.sales.previousEndDate
      })
          .catch((err) => {
            if(err && [401,403].includes(err.status)) {
              this.showSnackbar = true;
              this.messageSnackbar = 'Sessão expirou, redirecionando para o login...';
              setTimeout(() => {
                this.$router.push({name: 'Login'})
                this.$store.dispatch('auth/revokeAthentication')
              }, 2000)
            } else {
              console.error(err)
            }
          })
          .finally(() => {
            clearTimeout(this.__lazyLoadTimer__)
            this.isLoading = false
            this.clicked = false
          })
    },
    onClickSearch() {
      this.clicked = true
      this.lazyLoad()
      this.getSales()
    },
    lazyLoad() {
      this.__lazyLoadTimer__ = setTimeout(() => {
        this.isLoading = this.sales.status === 1 && this.clicked
      }, 300)
    }
  }
}
</script>
